<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="selector-block"
  >
    <div class="container">
      <div class="h2">{{ values?.title }}</div>

      <div class="selector__cards-wrapper">
        <SelectorCard
          v-for="card in fullValuesList"
          :key="`card-selector-${card.title}`"
          :card="card"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import { Category } from '#sitis/internal/controllers/categories/models/Category';
import type { ICategory } from '#sitis/internal/controllers/categories/models/Category';

export type SelectorBlockPropsValuesType = {
	button_text:string;
	category_id:number;
	features:{
		icon:number;
		text:string;
	}[]
	image:number;
	link:string;
	tags:{
		title:string;
		gradient_from:string;
		gradient_to:string;
		text_color?:string;
	}[];
	text_hover:string;
	title:string;
}

type SelectorBlockFullValuesType = SelectorBlockPropsValuesType &{
	name:string;
	minPrice?:number;
};

type SelectorBlockProps = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: {
    title: string;
		items:SelectorBlockPropsValuesType[];
	};
	cfgs: SectionTypes;
	extras: {
		categories: Array<any>;
	}
}
const { $customSectionClasses } = useNuxtApp();

const props = defineProps<SelectorBlockProps>();

const housesList = computed((): ICategory[] => props.extras?.categories?.map((t) => new Category(t).toJSON()));

const fullValuesList = computed(():SelectorBlockFullValuesType[] => props.values.items.map((t) => {
  const { category_id } = t;
  const list = (housesList.value || []).find(({ id }) => id === category_id);

  return {
    ...t,
    name: list?.name || '',
    minPrice: list?.dynamicFields?.min_price || ''
  };
}));

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.selector__cards-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-left: -30px;
	margin-top: -30px;

	& > div {
		width: calc(100% / 4 - 30px);
		margin-left: 30px;
		margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: inherit;
	}
}

@include media('xl') {
	.selector__cards-wrapper {
		margin-left: -16px;
		margin-top: -16px;

		& > div {
			width: calc(100% / 3 - 16px);
			margin-left: 16px;
			margin-top: 16px;
		}
	}
}

@include media('xl') {
	.selector__cards-wrapper {
		& > div {
			width: calc(100% / 2 - 16px);
		}
	}
}

@include media('md') {
	.selector__cards-wrapper {
		margin-left: 0;
		margin-top: -20px;

		& > div {
			width: calc(100%);
			margin-left: 0;
			margin-top: 20px;
		}
	}
}
</style>
